/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const quantityComponent = {
  decreaseSvg: {
    src: '/images/decresvg.svg',
    alt: 'decrease',
  },
  increaseSvg: {
    src: '/images/incresvg.svg',
    alt: 'increase',
  },
  leftIcon: {
    src: '/images/left-arrow-grey.svg',
    alt: 'Not Found',
  },
};

export const lockImage = {
  src: '/images/lock-3.svg',
  alt: 'lock',
};

export const itemErrorIcon = {
  src: '/images/itemError-mobile.png',
  alt: 'Error icon',
};

export const pickUpInStoreImg = {
  src: '/images/Pickup-In-Store-Graphic.svg',
  alt: 'pickupinstore',
};

export const bannerImg = {
  rewardsImg: '/images/rewards.svg',
  vehicleOrange: '/images/vehicle/orange_1.svg',
  iconStore: '/images/icon-store.svg',
  orangeStar: '/images/orangeStar.svg',
  promotionsIcon:
    'https://www.autozone.com/cdn/images/B2C/MX/content/mobile-first/FY21/ICONS/FY21-LP-Icon-Promotions.svg',
  locationIcon:
    'https://www.autozone.com/cdn/images/B2C/MX/content/mobile-first/FY21/ICONS/FY21-LP-Icon-Location.svg',
  loanToolIcon:
    'https://www.autozone.com/cdn/images/B2C/MX/content/mobile-first/FY21/ICONS/FY21-LP-Icon-LoanTool.svg',
};

export const visibleImg = {
  src: '/images/visible.svg',
  alt: 'Show',
};

export const hiddenImg = {
  src: '/images/hidden.svg',
  alt: 'Hide',
};

export const closeIconMedium = {
  src: '/images/close_icon.png',
  alt: 'Close',
};

export const closeIconSmall = {
  src: '/images/close-light.svg',
  alt: 'close',
};

export const primarySvg = {
  src: '/images/primary.svg',
  alt: 'AutoZone',
};

export const azLogoMedium = {
  src: '/images/az-logo-small.svg',
  alt: 'AutoZone',
};

export const closeButtonIcon = {
  src: '/images/closeButtonIcon.svg',
  alt: 'close',
};

export const autoZoneRewardsImg = {
  src: '/images/autoZoneRewards.png',
  alt: 'Rewards',
};

export const progressIndicatorInComplete = {
  src: '/images/progressIndicatorInComplete.svg',
  alt: 'Incomplete progress indicator.',
};

export const progressIndicatorComplete = {
  src: '/images/progressIndicatorComplete.svg',
  alt: '',
};

export const cartIcon = {
  src: '/images/az-cart-nav.svg',
  alt: 'shopping cart x items',
};

export const storeIcon = {
  src: '/images/store-new.svg',
  alt: 'My Store',
};

export const tickIcon = {
  src: '/images/vehicle-new-tick.png',
  alt: 'selected vehicle icon',
};

export const svgTickIcon = {
  src: '/images/tick-icon.svg',
  alt: 'selected vehicle icon',
};

export const vehicleIcon = {
  src: '/images/vehicle-new.svg',
  alt: 'vehicle icon',
};

export const vehicleIconOrange = {
  src: '/images/vehicle-new-orange.svg',
  alt: '',
};

export const arrowLeft = {
  src: '/images/arrow-left.svg',
  alt: 'left arrow',
};

export const hamburgerIcon = {
  src: '/images/hamburger_icon.svg',
  alt: '',
};

export const closeIcon = {
  src: '/images/close-dark.svg',
  alt: '',
};

export const close = {
  src: '/images/close.png',
  alt: 'close',
};

export const search = {
  src: '/images/search-grey.svg',
  alt: 'search',
};

export const searchGrey = {
  src: '/images/search-grey.svg',
  alt: 'search',
};

export const searchBlack = {
  src: '/images/search-black.svg',
  alt: 'search',
};

export const magnifyingGlass = {
  src: '/images/icon-search-20x20.svg',
  alt: 'search',
};

export const loadingAnimation = {
  src: '/images/az-loading-anim.svg',
  alt: 'loading',
};

export const downArrow = {
  src: '/images/down-arrow.svg',
  alt: '',
};

export const userIcon = {
  src: '/images/signin.svg',
  alt: '',
};

export const backButtonWhite = {
  src: '/images/arrow-left-white.svg',
  alt: 'back button',
};

export const pickUpInstoreIcon = {
  src: '/images/Pickup-In-Store-Graphic.svg',
  alt: '',
};

export const infoIcon = {
  src: '/images/info.svg',
  alt: 'info',
};

export const infoIconWithGreenColor = {
  src: '/images/info-green-icon.svg',
  alt: 'info',
};

export const nddIcon = {
  src: '/images/ndd-icon.svg',
  alt: '',
};

export const greenTickMark = {
  src: '/images/green-tickmark.svg',
  alt: '',
};

export const greenTickMark2 = {
  src: '/images/green-tickmark2.svg',
  alt: '',
};

export const whiteTickButton = {
  src: '/images/white-tick-button.svg',
  alt: 'roundedPlus',
};

export const orangeTickButton = {
  src: '/images/orange-thick.svg',
  alt: 'roundedPlus',
};

export const paypalButtonIcon = {
  src: '/images/paypal-logo.svg',
  alt: 'paypal icon',
};

export const giftCardIcon = {
  src: '/images/icon-gift-card.svg',
  alt: 'gift card',
};

export const creditCardIcon = {
  src: '/images/icon-credit-card.svg',
  alt: 'credit card',
};

export const itemDetail = {
  dealIcon: {
    src: '/images/dealIcon.svg',
    alt: 'promo',
  },
  giftCard: {
    src: '/images/gift-card.svg',
  },
  green: {
    src: '/images/vehicle/green.svg',
    alt: 'green car icon',
  },
  red: {
    src: '/images/vehicle/red.svg',
    alt: 'red car icon',
  },
  info: {
    src: '/images/info.svg',
    alt: 'info',
  },
  promoVote: {
    src: '/images/promoVote.png',
  },
  downArrow: {
    src: '/images/down-arrow.svg',
    alt: 'expand',
  },
};

export const orderSummaryInfo = {
  src: '/images/info_orderSummary.svg',
  alt: 'info',
};

export const promoVote = {
  src: '/images/promoVote.png',
  alt: 'promo',
};

export const imageConstant = {
  redExclamation: {
    src: '/images/red-exclamation.svg',
    alt: 'red exclamation',
  },
  warningExclamation: {
    src: '/images/notification/warning.svg',
    alt: 'warning exclamation',
  },
  confirmationCheck: {
    src: '/images/circle-check.svg',
    alt: 'confirmationChack',
  },
  numberOne: {
    src: '/images/number01.svg',
    alt: 'one',
  },
  numberTwo: {
    src: '/images/number02.svg',
    alt: 'two',
  },
  vehicleServiceIcon: {
    src: '/images/myVehicle.png',
    alt: 'vehicle icon',
  },
  storeIcon: {
    src: '/images/store-new.svg',
    alt: 'store location selected',
  },
  tickIcon: {
    src: '/images/vehicle-new-tick.svg',
    alt: 'selected vehicle icon',
  },
  vehicleIcon: {
    src: '/images/vehicle-new.svg',
    alt: ' ',
  },
  tagIcon: {
    src: '/images/tag.svg',
    alt: 'tag icon',
  },
  arrowLeft: {
    src: '/images/arrow-left.svg',
    alt: 'left arrow',
  },
  azLogoFull: {
    src: '/images/az-logo-full.svg',
    alt: '',
    height: 32,
    width: 265,
  },
  azLogoMedium: {
    src: '/images/az-logo-medium.svg',
    alt: '',
    height: 32,
    width: 212,
  },
  azLogoSmallSize: {
    src: '/images/az-logo-full.svg',
    alt: '',
    height: 18.24,
    width: 151.05,
  },
  azLogoMX: {
    src: '/images/logoautozonemx.svg',
    height: 54,
    width: 215,
  },
  digicertLogo: {
    src: '/images/footer/digicert.png',
    alt: '',
  },
  diyimage: {
    src: '/images/DIY-Advice.jpg',
    alt: 'diy',
  },
  hamburgerIcon: {
    src: '/images/hamburger_icon.svg',
    alt: 'menu',
  },
  closeIcon: {
    src: '/images/close-dark.svg',
    alt: 'close',
  },
  close: {
    src: '/images/close.png',
    alt: 'close',
  },
  search: {
    src: '/images/search.svg',
    alt: 'search',
  },
  liveChatSlider: {
    src: '/images/tag-live-chat.png',
    alt: '',
  },
  liveChatSliderHover: {
    src: '/images/live-chat.png',
    alt: '',
  },
  loadingAnimation: {
    src: '/images/az-loading-anim.svg',
    alt: 'loading',
  },
  lockIcon: {
    src: '/images/lock-3.svg',
    alt: '',
  },
  downArrow: {
    src: '/images/down-arrow.svg',
    alt: '',
  },
  userIcon: {
    src: '/images/signin.svg',
    alt: '',
  },
  backButtonWhite: {
    src: '/images/arrow-left-white.svg',
    alt: 'back button',
  },
  infoIcon: {
    src: '/images/info.svg',
    alt: 'info',
  },
  sddIcon: {
    src: '/images/sddIcon-19-17.svg',
    alt: '',
  },
  stdIcon: {
    src: '/images/stdDeliveryIcon-16-16.svg',
    alt: '',
  },
  nddIcon: {
    src: '/images/ndd-icon.svg',
    alt: '',
  },
  greenTickMark: {
    src: '/images/green-tickmark.svg',
    alt: '',
  },
  paypalButtonIcon: {
    src: '/images/paypal-logo.svg',
    alt: 'paypal icon',
  },
  paypalBorderedIcon: {
    src: '/images/paypal/PaypalBordered.svg',
    alt: 'paypal bordered icon',
  },
  applePayIcon: {
    src: '/images/applePay/ApplePay.svg',
    alt: 'apple pay icon',
  },
  gPayBorderedIcon: {
    src: '/images/googlePay/GpayBordered.svg',
    alt: 'google pay icon',
  },
  giftCardIcon: {
    src: '/images/icon-gift-card.svg',
    alt: 'gift card',
  },
  creditCardIcon: {
    src: '/images/icon-credit-card.svg',
    alt: 'credit card',
  },
  pinIcon: {
    src: '/images/arrow_icon_open.png',
    alt: 'pin icon',
  },
  leftArrow: {
    src: '/images/arrow-left-grey.svg',
    alt: 'left arrow',
  },
  leftPaginationArrow: {
    src: '/images/arrow-left-grey-3.svg',
    alt: 'left arrow',
  },
  rightArrow: {
    src: '/images/right-arrow.svg',
    alt: 'right arrow',
  },
  rightCarat: {
    src: '/images/right-carat.svg',
    alt: 'right arrow',
  },
  rightArrowActive: {
    src: '/images/arrow-right.svg',
    alt: 'right arrow',
  },
  rightArrowGrey: {
    src: '/images/arrow-right-grey.svg',
    alt: 'right arrow',
  },
  rightPaginationArrowSuperInactive: {
    src: '/images/arrow-right-grey-3.svg',
    alt: 'right arrow',
  },
  rightArrowIcon: {
    src: '/images/right-arrow.svg',
    alt: 'right arrow',
  },
  rightArrowOrder: {
    src: '/images/right-arrow.svg',
    alt: 'Order details',
  },
  rightArrowFitment: {
    src: '/images/fitment-right-arrow.svg',
    alt: 'view buyers guide',
  },
  email: {
    src: '/images/az-email.svg',
  },
  emailEnvelopeWhite: {
    src: '/images/email-envelope.svg',
    alt: 'white email envelope icon',
  },
  emailEnvelope: {
    src: '/images/az-email-envelope.png',
    alt: 'email',
  },
  printer2x: {
    src: '/images/az-printer-2x.png',
    alt: 'print',
  },
  printIcon: {
    src: '/images/print.svg',
    alt: 'print',
  },
  calendar: {
    src: '/images/calendar.svg',
    alt: 'calendar',
  },
  closeDark: {
    src: '/images/close-dark.png',
    alt: 'close',
  },
  scoreImage: [
    {
      src: '/images/rewards/0.gif',
      alt: 'rewards',
    },
    {
      src: '/images/rewards/1.gif',
      alt: 'rewards',
    },
    {
      src: '/images/rewards/2.gif',
      alt: 'rewards',
    },
    {
      src: '/images/rewards/3.gif',
      alt: 'rewards',
    },
    {
      src: '/images/rewards/4.gif',
      alt: 'rewards',
    },
    {
      src: '/images/rewards/5.gif',
      alt: 'rewards',
    },
  ],
  fitsTickMark: {
    src: '/images/fits.png',
    alt: 'Tickmark',
  },
  fitmentCar: {
    src: '/images/fitmentCar.svg',
    alt: '',
  },
  logoMobile: {
    src: '/images/logo-mobile.png',
    alt: 'Logo',
  },
  autozoneLogo: {
    src: '/images/Autozonelogo.png',
    alt: 'Logo',
  },
  leftOrange: {
    src: '/images/arrow-left-orange.svg',
    alt: 'left arrow',
  },
  arrowOrange: {
    src: '/images/arrow-right-lightOrange.svg',
    alt: 'Manage vehicle',
  },
  myVehicle: {
    src: '/images/myVehicle.png',
    alt: 'vehicle',
  },
  arrowUp: {
    src: '/images/arrow_up.png',
    alt: 'arrowIcon',
  },
  arrowDown: {
    src: '/images/arrow_down.png',
  },
  rightArrowSmall: {
    src: '/images/right_arrow_small.png',
    alt: '',
  },
  menuClose: {
    src: '/images/icon-control-close-12.svg',
    alt: 'Close window',
  },
  resetPwdRewardIcon: {
    src: '/images/rewards.svg',
    alt: 'resetPassword Rewards',
  },
  resetPwdStoreIcon: {
    src: '/images/icon-store.svg',
    alt: 'resetPassword Store',
  },
  resetPwdSettingIcon: {
    src: '/images/wrench-screwdriver.svg',
    alt: 'resetPassword Setting',
  },
  generalComments: {
    src: '/images/contact-us-general-orange.svg',
    alt: '',
  },
  OrderQuestionsText: {
    src: '/images/contact-us-order-orange.svg',
    alt: '',
  },
  productQuestions: {
    src: '/images/contact-us-product-orange.svg',
    alt: '',
  },
  websiteIssues: {
    src: '/images/contact-us-website-orange.svg',
    alt: '',
  },
  rewardsIcon: {
    src: '/images/contact-us-rewards-orange.svg',
    alt: '',
  },
  storeExperience: {
    src: '/images/contact-us-store-orange.svg',
    alt: '',
  },
  returnItems: {
    src: '/images/contact-us-return-orange.svg',
    alt: '',
  },
  frequent: {
    src: '/images/contact-us-frequent-orange.svg',
    alt: '',
  },
  Forward: {
    src: '/images/arrow-right.svg',
    alt: '',
    width: 16,
    height: 16,
  },
  magnifyingGlass: {
    src: '/images/magnifying_glass.svg',
    alt: 'search',
  },
  smallSpeedBar: {
    src: '/images/small-speedbar.svg',
    alt: 'speedbar',
  },
  speedBarDivider: {
    src: '/images/speedbar-divider.svg',
    alt: 'speed-divider',
  },
  whiteTickButton: {
    src: '/images/white-tick-button.svg',
    alt: 'roundedPlus',
  },
  roundedPlus: {
    src: '/images/roundedPlus.svg',
    alt: 'roundedPlus',
  },
  vehicleLightGrey: {
    src: '/images/vehicle/light-gray.svg',
    alt: '',
  },
  vehicleOrange: {
    src: '/images/vehicle/orange.svg',
    alt: '',
  },
  gLocation: {
    src: '/images/location.svg',
    alt: 'geo-location',
  },
  homeStore: {
    src: '/images/pin-selected.svg',
    alt: 'Selected Store',
  },
  rewardsPhone: {
    src: '/images/rewardsphone.png',
    alt: 'Rewards Advt.',
  },
  chatBubble: {
    src: '/images/az-chat-bubble-circle-information-orange.svg',
    alt: 'circle bubble',
  },
  userOrange: {
    src: '/images/az-user-orange.svg',
    alt: '',
  },
  cartFavoriteStar: {
    src: '/images/az-shopping-cart-favorite-star-orange.svg',
    alt: '',
  },
  chatSquare: {
    src: '/images/az-chat-bubbles-square-orange.svg',
    alt: '',
  },
  cardHighlight: {
    src: '/images/card-highlight.png',
    alt: 'Shopping cart',
  },
  vehicleManagement: {
    src: '/images/vehicle-management-orange.svg',
    alt: '',
  },
  receiptOrange: {
    src: '/images/az-receipt-orange.svg',
    alt: '',
  },
  rewardsLogoImage: {
    src: '/images/rewards/AZRewardsLogo.svg',
    alt: 'AutoZone Rewards',
  },
  rewardsImage: {
    src: '/images/rewards/',
    alt: 'credits earned',
  },
  shapeLeftArrow: {
    src: '/images/shape_left_arrow.svg',
    alt: 'car icon',
  },
  shapeLeftArrowLightGrey: {
    src: '/images/shape_left_arrow_light_grey.svg',
    alt: 'arrow icon',
  },
  lightGray: {
    src: '/images/vehicle/light-gray.svg',
    alt: 'car icon',
  },
  checkmarkIcon: {
    src: '/images/checkmark-copy-6.svg',
    alt: 'checkmark',
  },
  trackOrderIcon: {
    src: '/images/track-my-order.svg',
    alt: 'track my order',
  },
  locationIcon: {
    src: '/images/az-location.svg',
    alt: 'location',
  },
  visaCvvImg: {
    src: '/images/visa-cvv.jpg',
    alt: 'cvv',
  },
  amexCvvImg: {
    src: '/images/amex-cvv.jpg',
    alt: 'amex',
  },
  grayInfoIcon: {
    src: '/images/grey-04-dark.png',
    alt: 'grayInfoIcon',
  },
  proformanceIcon: {
    src: '/images/az-proformance-warranty.png',
    alt: 'proformance',
  },
  leftArrowCarousel: {
    src: '/images/left_arrow.png',
    alt: 'left arrow',
  },
  greenCar: {
    src: '/images/vehicle/green.svg',
    alt: '',
  },
  greyCarImg: {
    src: '/images/vehicle/gray.svg',
    alt: '',
  },
  fitmentCarImg: {
    src: '/images/fitmentCar.svg',
    alt: '',
  },
  itemErrorMobileImg: {
    src: '/images/itemError-mobile.png',
    alt: 'error',
  },
  rewardCardImg: {
    src: '/images/rewardsCard.png',
    alt: 'cardInfo',
  },
  closeIconGrey: {
    src: '/images/close-grey.svg',
    alt: 'closeCardInfo',
  },
  rewardsTooltip: {
    src: '/images/info.svg',
    alt: 'info',
  },
  rewardsAppliedImg: {
    src: '/images/rewardsApplied.png',
    alt: 'Rewards Applied',
  },
  checkoutRewardsIcon: {
    rewardsrc: '/images/rewards-card.png',
    alt: 'rewards',
  },
  checkoutGiftCardIcon: {
    giftsrc: '/images/gift-card.png',
    alt: 'giftCard',
  },
  autoZoneReceipt: {
    src: '/images/auto-zone-receipt.png',
    alt: '',
  },
  orderHistoryImage: {
    src: '/images/order-history-screenshot.png',
    alt: '',
  },
  lockIconImg: {
    src: '/images/lock-3.svg',
    alt: 'lock icon',
  },
  amexIcon: {
    src: '/images/amex.png',
    alt: 'american Express',
  },
  discoverIcon: {
    src: '/images/discover.png',
    alt: 'Discover',
  },
  masterCard: {
    src: '/images/master-card.png',
    alt: 'Master Card',
  },
  visaCard: {
    src: '/images/visa.png',
    alt: 'VISA',
  },
  bubbleSquare: {
    src: '/images/chat-bubbles-square.svg',
    alt: 'chat',
  },
  promoCodeShoppingCart: {
    src: '/images/shopping-cart-star.svg',
    alt: 'cart logo',
  },
  promoCodeIcon: {
    src: '/images/promoIcon.svg',
    alt: 'promo',
  },
  itemErrorImage: {
    src: '/images/itemError.png',
    alt: 'itemError',
  },
  deliveryTruck: {
    src: '/images/delivery-truck.svg',
    alt: 'Delivery Truck',
  },
  creditCardCheck: {
    src: '/images/credit-card-check.svg',
    alt: 'Credit Card',
  },
  nextDayDelivery: {
    src: '/images/next-day-delivery.png',
    alt: 'next-day-delivery',
  },
  vehicleFit: {
    src: '/images/vehicleFit.svg',
    alt: 'carIcon',
  },
  printerIcon: {
    src: '/images/printerIcon.png',
    alt: 'print',
  },
  rectangle: {
    src: '/images/rectangle.png',
    alt: 'underline',
  },
  rewardsCard: {
    src: '/images/rewards-card-tablet',
    alt: 'Order Confimation Rewards',
  },
  storePickUpIcon: {
    src: '/images/az-store.svg',
    alt: 'store pick up',
  },
  userPosition: {
    src: '/images/oval.svg',
    alt: 'Current Position',
  },
  geoLocateBtn: {
    src: '/images/group-7.svg',
    alt: 'Geographical Location Button',
  },
  autozoneChatLogo: {
    src: '/images/auto-zone-logo-full-rgb.svg',
    alt: 'AutoZone',
  },
  chatPaperClip: {
    src: '/images/paperclip.svg',
    alt: 'attach icon',
  },
  rightArrowOrange: {
    src: '/images/arrow-right-lightOrange.svg',
    alt: 'right arrow',
  },
  warningPDP: {
    src: '/images/warningPDP.png',
    alt: 'warning',
  },
  pencilWhite: {
    src: '/images/footer/pencil-write.svg',
    alt: 'write pencil',
  },
  orangeReadLessArrow: {
    src: '/images/readLessArrow.svg',
    alt: 'top Arrow',
  },
  orangeReadMoreArrow: {
    src: '/images/readMoreArrow.svg',
    alt: 'down Arrow',
  },
  greenTickRoundedMA: {
    src: '/images/greenTickRoundedMA.png',
    alt: 'green tick',
  },
  whiteVehicle: {
    src: '/images/vehicle/white.svg',
    alt: 'white vehicle',
  },
  azReceipt: {
    src: '/images/az_receipt.svg',
    alt: 'receive promotions',
  },
  fitmentWarning: {
    src: '/images/icon-fitment-warning.svg',
    alt: '',
  },
  fitmentError: {
    src: '/images/icon-fitment-error.svg',
    alt: '',
  },
  fitmentFits: {
    src: '/images/icon-fitment-fit.svg',
    alt: '',
  },
  azRewardsIcon: {
    src: '/images/featuredicons-rewards.svg',
    alt: '',
  },
  navigationIcon: {
    src: 'images/icon-navigation.svg',
    alt: 'navigation',
  },
  phoneIcon: {
    src: 'images/icon-phone.svg',
    alt: 'phone',
  },
};

export const mapReplacementImg = {
  imgAlt: 'AutoZone',
  srcRoot: '/images/',
  srcUS: 'US',
  srcMX: 'MX',
  srcBase: '_map_replacement.png',
};

export const leftArrowOrange = {
  src: '/images/arrow-left-orange.svg',
  alt: 'left arrow',
};

export const arrowDown = {
  src: '/images/az-down-arrow.svg',
  alt: '',
};

export const paypalText = {
  src: '/images/paypal-text.svg',
};

export const orderRewards = {
  src: '/images/order-rewards.png',
  alt: 'Order Confirmation Rewards',
};

export const filterMobile = {
  src: '/images/filterMobile.svg',
  alt: '',
};

export const closeLight = {
  src: '/images/close-light.svg',
  alt: 'close',
};

export const vehicleIconSource = '/images/vehicle-new.svg';

export const storeIconSource = '/images/store-new.svg';

export const vehicleSelectedIconSource = {
  mobile: '/images/icon-vehicle-selected-m.svg',
  tablet: '/images/icon-vehicle-selected-t.svg',
  desktop: '/images/icon-vehicle-selected-d.svg',
};

export const storeSelectedIconSource = {
  mobile: '/images/icon-store-selected-m.svg',
  tablet: '/images/icon-store-selected-t.svg',
  desktop: '/images/icon-store-selected-d.svg',
};

export const twentyFourServiceImg = {
  src: '/images/24.svg',
};

export const warrantyImg = {
  src: '/images/warranty.svg',
};

export const warrantyImgExpired = {
  src: '/images/warrantyExpired.svg',
  alt: 'Expired Warranty',
};

export const blackWarrantyImg = {
  src: '/images/blackWarranty.svg',
  alt: 'No Warranties found',
};

export const warrantyImgGreen = {
  src: '/images/warrantyGreen.svg',
  alt: 'Active Warranty',
};

export const warrantyImgRed = {
  src: '/images/warrantyRed.svg',
};

export const batteryChargeImg = {
  src: '/images/battery-charge.svg',
};

export const toolsImg = {
  src: '/images/tools.svg',
};

export const oilCanImg = {
  src: '/images/oil-can.svg',
};

export const batteryServiceImg = {
  src: '/images/battery.svg',
};

export const herramientasImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/HERRAMIENTAS-01.svg',
};

export const serviciosGratuitosImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/SERVICIOS-GRATUITOS.svg',
};

export const sltsImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/SLTS-03-01.svg',
};

export const cargaDeBateriasImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/CARGA-DE-BATERIAS-04-01.svg',
};

export const checkEngineImg = {
  src: 'https://www.autozone.com/cdn/images/MF/MX/ICONOS/CHECK-ENGINEE-05.svg',
};

export const comingSoonImg = {
  src: '/images/product-photo-coming-soon-m.jpg',
};

export const azAppImg = {
  src: '/images/AZ_DIY_AppIcon_1x.png',
  alt: 'AZ',
};

export const sliderControls = {
  leftArrow: {
    src: '/images/left-arrow-grey.svg',
    alt: 'Next slide',
  },
  rightArrow: {
    src: '/images/right-arrow-grey.svg',
    alt: 'Previous slide',
  },
};

export const azOrangeReceipe = {
  src: '/images/shape-receipe.png',
  alt: 'Orange Receipe',
};
